import React from 'react'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

const SEO = ({ description, title, keywords }) => (
  <StaticQuery
    query={`${SeoQuery}`}
    render={(data) => {
      const metaTitle = title
        ? `${title}`
        : data.site.siteMetadata.title
      const metaDescription = description || data.site.siteMetadata.description
      const metaKeywords = keywords

      return (
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />
          <meta name="keywords" content={metaKeywords} />
					<meta name="google-site-verification" content="y_Ft-EjMyOV-5YFmHAYrNruPUk0B6M9Ed_Gz3Ys3kz0" />
        </Helmet>
      )
    }}
  />
)

const SeoQuery = graphql`
query {
  site {
    siteMetadata {
      title
      description
    }
  }
}
`

export default SEO
